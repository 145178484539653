<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <button
        v-for="status in statuses"
        :key="status.id"
        @click="getList(status.status)"
        class="btn btn-primary text-uppercase mb-2"
        :class="{ 'btn-success': (status.status == selectedStatus) }"
      >
        <small>{{ status.status }}</small>
      </button>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <table class="table table-bordered">
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Purpose</th>
              <th>Amount</th>
              <th>Cmt</th>
              <th></th>
            </tr>
            <tr v-for="row in list" :key="row.id">
              <td>{{ row.created_at }}</td>
              <td>
                <div class="text-uppercase">{{ row.name }}</div>
                <div>{{ row.email }}, {{ row.phone }}</div>
              </td>
              <td>{{ row.purpose }}</td>
              <td>{{ row.amount }}</td>
              <td>
                <div class="text-wrap" style="width:200px">{{ row.cmt }}</div>
              </td>
              <td>
                <span class="badge badge-info pointer" @click="openModal(row)">open</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <modal :show.sync="isModalActive" modal-classes="modal-dialog-centered modal-lg">
      <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Update status</h5>
      </template>
      <div>
        <form @submit.prevent="submit">
          <div class="row mb-3">
            <div class="col">
              <input class="form-control" v-model="form.cmt" type="text" placeholder="Cmt" />
            </div>
            <div class="col">
              <select class="form-control" v-model="form.status">
                <option
                  v-for="status in statuses"
                  :key="status.id"
                  :value="status.status"
                >{{ status.status }}</option>
              </select>
            </div>
          </div>

          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="isModalActive = false">Close</base-button>
      </template>
    </modal>
  </div>
</template>


<script>
export default {
  data() {
    return {
      pListKey: 0,
      selectedStatus: "pending",
      statuses: [],
      list: [],
      selectedRow: {},
      isModalActive: false,
      form: {
        cmt: "",
        status: ""
      }
    };
  },
  mounted() {
    this.init();
    api.borrower.statuses().then(res => {
      this.statuses = res.data.data;
    });
  },
  methods: {
    init() {
      this.getList(this.selectedStatus);
    },
    getList(status) {
      this.saving();
      this.selectedStatus = status;
      api.borrower.getStatus(status).then(res => {
        this.list = res.data.data;
        this.saved();
      });
    },
    submit(e) {
      e.preventDefault();
      api.borrower.updateStatus(this.selectedRow.id, this.form).then(res => {
        this.isModalActive = false;
        this.init();
      });
    },
    openModal(row) {
      this.form.cmt = row.cmt;
      this.form.status = row.status;
      this.selectedRow = row;
      this.isModalActive = true;
    }
  }
};
</script>